import React, { Component } from "react";
import {
  Button,
  Modal,
  ListGroup,
  Form,
  Spinner,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import renderTextarea from "../components/inputs/rederTextarea";
// import { PURGE } from "redux-persist";
// import uuid from "react-uuid";
import { Redirect } from "react-router-dom";
import Iframe from "react-iframe";

import { uploadCartSuccess } from "../actions/cartActions";

import { postOrder } from "../service/orderService";
// import { initiateTransaction } from '../service/paymentService'

class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmited: false,
      showUserErrorAlert: false,
      showOrderSubmitedAlert: false,
      showPaymentError: 0,
      onInitPaymentSuccessRedirect: "",
      onSumCheck: false,
      setEnableToOrder: false,
      enableToOrderModal:false
      // loginErrorModal:false,
      // orderSendModal:false
    };
    this.handleOrderSubmit = this.handleOrderSubmit.bind(this);
    this.onPurgeStoredState = this.onPurgeStoredState.bind(this);
    this.onShowUserErrorAlert = this.onShowUserErrorAlert.bind(this);
    this.onShowOrderSubmitedAlert = this.onShowOrderSubmitedAlert.bind(this);
    this.sumCheck = this.sumCheck.bind(this);
  }

  onPurgeStoredState() {
    this.props.uploadCartSuccess();
    localStorage.removeItem("persist:shorpping_cart");
    // window.location.replace("https://flyingdragon.co.nz")
  }

  sumCheck(sum) {
    if (sum > 5) {
      // console.log(">5");
      this.setState({ onSumCheck: true });
    } else {
      // console.log("<5");
      this.setState({ onSumCheck: false });
    }
  }

  componentDidMount(){
    this.openingTimeCheck()
  }

  openingTimeCheck() {

    const opening = [
      [16, 20], // Sunday ...
      [16, 20],
      [16, 20],
      [11.3, 14, 16, 20],
      [11.3, 14, 16, 20],
      [11.3, 14, 16, 20],
      [11.3, 14, 16, 20], // Saturday
    ];

    let current_date = new Date();
    let today = current_date.getDay();

    let openingTimes = opening[today];

    let current_time = Number(
      current_date.getHours() + "." + current_date.getMinutes()
    );

    if (openingTimes.length > 0) {
      for (let index = 0; index < openingTimes.length; index = index + 2) {
        if ( openingTimes[index] <= current_time && current_time <= openingTimes[index + 1] ) {
          this.setState({setEnableToOrder:true})
          this.setState({enableToOrderModal:true})
        }
      }
    }

    // console.log(`openingTimes`, openingTimes)
    // console.log(`current_time`, current_time);
  }

  handleOrderSubmit = async (values, dispatch, total) => {



    let pt = new Date(new Date().getTime() + 20 * 60000);
    let info = JSON.stringify("{pickup:" + pt + "}");

    this.openingTimeCheck();
    values.store = "flyingdragon";
    values.userId = this.props.user.userId;
    values.order_no = Math.floor(Math.random() * 100000);
    values.data = this.props.myCart;
    values.payment_status = "ready";
    values.payment_recived = false;
    values.total_charge = total.toFixed(2) * 100;
    values.order_date = new Date();
    values.shipping_info = info;

    console.log('user', this.props.user)
    console.log(`-values-`, values);
    console.log(this.props.isLoggedIn)
    if (this.props.isLoggedIn) {
      return await postOrder(values).then(
        (res) => {
          // console.log("post return > ", res);
          dispatch(reset("ShoppingCart"));
          console.log(res.data)

          if (res.data.payment_conn.Success) {
            this.setState({
              onInitPaymentSuccessRedirect: res.data.payment_conn.NavigateURL,
            });
          }
          this.onPurgeStoredState();
        },
        (rej) => {
          this.onShowUserErrorAlert();
        }
      );
    } else {
      this.onShowUserErrorAlert();
    }
  };

  onShowUserErrorAlert = () => {
    this.setState({ showUserErrorAlert: true }, () => {
      window.setTimeout(() => {
        this.setState({ showUserErrorAlert: false });
      }, 3000);
    });
  };

  onShowOrderSubmitedAlert = () => {
    this.setState({ showOrderSubmitedAlert: true }, () => {
      window.setTimeout(() => {
        this.setState({ showOrderSubmitedAlert: false });
      }, 3000);
    });
  };

  render() {
    const { error, handleSubmit, submitting } = this.props;
    const { myCart } = this.props;
    // console.log('setEnableToOrder << BUTTON >>', this.state.setEnableToOrder)
    // console.log('enableToOrderModal', this.state.enableToOrderModal)
    let sum = 0;

    return (
      <>
        <Container>
          <div className="m-4">
            <h3> Your Shopping Cart </h3>{" "}
          </div>
          {this.state.showPaymentError !== 0 && (
            <>
              <Alert dismissible variant="danger">
                <Alert.Heading>
                  {" "}
                  Ooops!We unable to process your Order!{" "}
                </Alert.Heading>{" "}
                <p> Please call us on 06 7591886 for Pickup. </p>{" "}
                <p> Error: {this.state.showPaymentError}. </p>{" "}
              </Alert>{" "}
            </>
          )}
          {this.state.onInitPaymentSuccessRedirect &&
            (window.location.href =
              this.state.onInitPaymentSuccessRedirect)
            // <Iframe
            //   url={this.state.onInitPaymentSuccessRedirect}
            //   width="100%"
            //   min-height="100%"
            //   id="myId"
            //   className="iframeContainer"
            //   display="block"
            //   position="fixed"
            // />
          }
          {this.state.showUserErrorAlert && (
            <>
              <Alert dismissible variant="danger">
                <Alert.Heading> Ooops!You are not login yet! </Alert.Heading>{" "}
                <p> Login before make an order please. </p>{" "}
              </Alert>{" "}
            </>
          )}
          {this.state.showOrderSubmitedAlert && (
            <>
              <Alert dismissible variant="success">
                <Alert.Heading> Order been sent. </Alert.Heading>{" "}
                <p> Please wait for Emails.And checking your Orders page. </p>{" "}
              </Alert>{" "}
              <Redirect to="/orders" />
            </>
          )}{" "}
          {myCart.length > 0 && (
            <>
              <Col />
              <Col>
                <ListGroup>
                  {" "}
                  {myCart.map((item, index) => {
                    sum += Object.values(item)[0].price / 100;
                    return (
                      <ListGroup.Item key={index}>
                        {" "}
                        {"[" + Object.values(item)[0].category + "] "}{" "}
                        {Object.values(item)[0].name}{" "}
                        {"   $" + Object.values(item)[0].price / 100}{" "}
                        {Object.values(item)[0].configuer.length > 0 && (
                          <ListGroup>
                            {" "}
                            {Object.values(item)[0].configuer.map(
                              (ingredient, indx) => (
                                <ListGroup.Item key={indx}>
                                  {" "}
                                  {"- " + Object.values(ingredient)[0]}{" "}
                                </ListGroup.Item>
                              )
                            )}{" "}
                          </ListGroup>
                        )}{" "}
                        {Object.values(item)[0].extras.length > 0 && (
                          <ListGroup>
                            {" "}
                            {Object.values(item)[0].extras.map(
                              (ingredient, indx) => {
                                sum +=
                                  Object.values(ingredient)[0].price.regular /
                                  100;
                                return (
                                  <ListGroup.Item key={indx}>
                                    {" "}
                                    {"+ " +
                                      Object.values(ingredient)[0].name +
                                      "  $" +
                                      Object.values(ingredient)[0].price
                                        .regular /
                                        100}{" "}
                                  </ListGroup.Item>
                                );
                              }
                            )}{" "}
                          </ListGroup>
                        )}{" "}
                      </ListGroup.Item>
                    );
                  })}{" "}
                </ListGroup>{" "}
                <br />
                <h5>Total: {"$" + sum.toFixed(2) + " incl gst"}</h5>
                <hr />
                <hr />
                <Form
                  onSubmit={handleSubmit((values, dispatch) =>
                    this.handleOrderSubmit(values, dispatch, sum)
                  )}
                >
                  <div className="mr-auto">
                    <Form.Label> Order Notes : minimum order $5.00</Form.Label>{" "}
                    <Field name="note" component={renderTextarea} />{" "}
                  </div>

                  <br />
                  <br />
                  <p>Order Must be Pick Up from Flying Dragon Takeaways </p>
                  <p>
                    <a
                      href="https://goo.gl/maps/t2Zxsnyu7A63cpvr5"
                      target="_blank" rel="noreferrer"
                    >
                      37 Tukapa Street Westown, New Plymouth. Phone:759-1886{" "}
                    </a>
                  </p>
                  <p>
                    We only accept orders within Taranaki Region New Zealand!
                  </p>
                  <p>No refun apply to any orders, only credit to account. </p>
                  <br />
                  <Button
                    variant="secondary"
                    onClick={() => this.onPurgeStoredState()}
                  >
                    {" "}
                    Delete Order{" "}
                  </Button>

                  <Button 
                   variant={this.state.setEnableToOrder?   "primary": "danger"}
                  type="submit" 
                  disabled={submitting || !this.state.setEnableToOrder}
                  >
                    {" "}
                    {this.state.setEnableToOrder ? (
                      "Agree And Make Order" 
                    ) : (
                      "Not Open Yet"
                    )}{" "}

                  </Button>
                  <hr />
                  {error && <strong> {error} </strong>}

                  <p>
                    {" "}
                    <a href="https://www.polipay.co.nz/" target="blank">
                      {" "}
                      HTTPS Secure payment will process by our internet banking
                      provider POLI payment.{" "}
                    </a>
                  </p>
                </Form>{" "}
              </Col>{" "}
              <Col />
            </>
          )}
          {myCart.length <= 0 && (
            <>
              <Col />
              <Col>
                <h5> Shoping Cart has no items </h5>{" "}
              </Col>{" "}
              <Col />
            </>
          )}{" "}
        </Container>

        <Modal
          show={this.state.showUserErrorAlert}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          
        >
          <Modal.Header>
            <Modal.Title>Can not Order!</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Ooops!We unable to process your Order!</p>
            <p> Login before make an order please. </p>{" "}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showPaymentError !== 0}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Can not Order!</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Ooops!We unable to process your Order!</p>
            <p> Please call us on 06 7591886 for Pickup. </p>{" "}
            <p> Error: {this.state.showPaymentError}. </p>{" "}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showOrderSubmitedAlert}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Order been sent!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p> Please wait for Emails.And checking your Orders page. </p>
          </Modal.Body>
        </Modal>

        <Modal
          show={!this.state.enableToOrderModal}
          onHide={() => this.setState({ enableToOrderModal: true })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>We are not open yet.</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h3>Today: {new Date().toLocaleString(
                    "en-NZ",
                    "New Zealand/Auckland"
                  )}
                  </h3>
            <h5>Sorry, We are not open yet.</h5>
            <h5>We can not process your order, come back on below time. </h5>
            <Row style={{ justifyContent: "center" }}>
              <h5 >Lunch : Wednesday to Saturday 11:30am-2:00pm </h5>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <h5>Dinner : Monday to Sunday 4:00pm-9:00pm</h5>
              </Row>
            <Row style={{ justifyContent: "center" }}>
              <h5 style={{color: '#FF101F', backgroundColor:'#ff9f0a'}}>Online Orders only open up to 8PM</h5>
            </Row>

          </Modal.Body>
        </Modal>

      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    myCart: state.shoppingCart.myCart,
    user: state.AuthReducer,
    isLoggedIn: state.AuthReducer.isLoggedIn,
    access_token: state.AuthReducer.access_token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    uploadCartSuccess: () => dispatch(uploadCartSuccess()),
  };
}

ShoppingCart = connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);

export default reduxForm({
  form: "ShoppingCart", // a unique identifier for this form
})(ShoppingCart);
