import axios from 'axios';

axios.defaults.baseURL = 'https://efun.co.nz/flying/api';


export const postOrder = ( order ) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/Orders',
      headers: {
        'Content-Type': 'application/json'
      },
      data: order
    })
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      reject(err.response);
    });
  });
}



export const getOrders = ( userId ) => {
   let url = '/users/'+userId+'/Orders';

   axios.interceptors.request.use(request => {
    // console.log('Starting Request', JSON.stringify(request, null, 2))
    return request
  })
  

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        // console.log(`service res`, res)
        resolve(res.data);
      })
      .catch(err => {
        // console.log(`service res`, err)
        reject(err.response);
      });
    });
  }

  export const updateOrderPaymentStatus= ( order_id, status) =>{

   return new Promise((resolve, reject) => axios.patch('/Orders/'+order_id, status)
    .then(res => {
      resolve(res);
    })
    .catch(err=>reject(err))
   )
  }