import React, { Component } from 'react'
// import { Field, reduxForm } from 'redux-form'
// import renderTextBox from './renderTextBox'
// import { Form, Button, Spinner } from 'react-bootstrap'
import { reset } from 'redux-form'
import CreateProduct from '../components/inputs/createProduct'
import CreateCategory from '../components/inputs/createCategory'
import { postCategory, getCategories } from '../service/categoryService'
import { postProducts, getProducts } from '../service/productService'

import { Container, Row, Col, ListGroup } from 'react-bootstrap'


class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: null
    }
  }

  async componentDidMount() {
    let incomeCategories = await getCategories()
    if (incomeCategories) {
      this.setState({ categories: incomeCategories })
    }
  }


  createCategory = async (values, dispatch) => {
    return postCategory(values).then(
      res => {
        dispatch(reset('CreateCategory'))
        return res
      },
      reject => {
      }
    )
  }

  createProduct = async (values, dispatch) => {

    if (values.config)
      values.config = JSON.parse(values.config)
    if (values.price)
      values.price = JSON.parse(values.price);
    if (values.status)
      values.status = JSON.parse(values.status)
    // console.log('values.display_level', values.display_level)
    if (values.include_products)
      values.include_products = JSON.parse(values.include_products)
    if (values.display_level)
      values.display_level = JSON.parse(values.display_level)
    if (values.data)
      values.data = JSON.parse(values.data)
    if (values.add_product_menu)
    values.add_product_menu = JSON.parse(values.add_product_menu)

    return postProducts(values).then(
      res => {
        dispatch(reset('CreateProducts'))
        return res
      },
      reject => {
      }
    )
  }


  render() {
    return (
      <Container>
        <Row>
          <Col>
            <ListGroup>
              {this.state.categories && this.state.categories.map((category, index) => (
                <ListGroup.Item key={index}>{category.name}</ListGroup.Item>
              )
              )}

            </ListGroup>
            <br />

            <CreateCategory onSubmit={(values, dispatch) => this.createCategory(values, dispatch)} categoies={this.state.categories} />
          </Col>
          <Col>
            <CreateProduct onSubmit={(values, dispatch) => this.createProduct(values, dispatch)} categoies={this.state.categories} />
          </Col>
        </Row>
      </Container>
    )
  }
}
export default Admin