
import {
  Login,
  Logout,
  Register,
  RegisterSuccess,

  SetUserData,
  SetUserAuth,

  LoginAsCustomer,
  LoginAsStoreOwner,
  LoginAsStoreManager,
  LoginAsStoreWorker,
  LoginAsStoreAdmin
} from './actionTypes';

export const login = () => ({
    type: Login
  });
  
  export const logout = () => ({
    type: Logout
  });
  
  export const register = () => ({
    type: Register
  });
  
  export const registerSuccess = (userdata) => ({
    type: RegisterSuccess,
    payload: userdata
  });

  export const setUserData = (user) => ({
    type: SetUserData,
    payload: user
  });
  
  export const setUserAuth = (userAuth) => ({
    type: SetUserAuth,
    payload: userAuth
  });
  
  export const loginAsCustomer = () => ({
    type: LoginAsCustomer
  });
  
  export const loginAsStoreOwner = () => ({
    type: LoginAsStoreOwner
  });

  export const loginAsStoreManager = () => ({
    type: LoginAsStoreManager
  });
  export const loginAsStoreWorker = () => ({
    type:   LoginAsStoreWorker
  });
  export const loginAsStoreAdmin = () => ({
    type: LoginAsStoreAdmin
  });