import axios from 'axios';

axios.defaults.baseURL = 'https://efun.co.nz/flying/api';


export const postProducts = (postCat) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/Products',
      headers: {
        'Content-Type': 'application/json'
      },
      data: postCat
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.response);
      });
  });
}

export const getProducts = () => {
  return new Promise((resolve, reject) => {

    axios({
      method: 'get',
      url: '/Products',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 400 || res.status === 403) {
          reject(res);
        }
        resolve(res.data);

      })
      .catch(err => {
        reject(err.response);
      });
  });
}