import {createStore, applyMiddleware} from 'redux'
import rootReducer from '../reducer/index'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist' 
import { composeWithDevTools } from 'redux-devtools-extension';

function configureStore() {
  let store = createStore(
    rootReducer,
    // composeWithDevTools(applyMiddleware(thunk))
    applyMiddleware(thunk)
    
  );

  let persistor = persistStore(store);
  return { persistor, store };
}

export default configureStore;