import axios from 'axios';
import {
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,

  FETCH_CATEGORIES_BEGIN,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,

  ADD_CONFIG,
  REMOVE_CONFIG,

  ADD_EXTRA,
  REMOVE_EXTRA,

  CLEAR_CONFIG

} from './actionTypes';
import { getProducts } from '../service/productService'
import { getCategories } from '../service/categoryService'

axios.defaults.baseURL = 'https://efun.co.nz/flying/api';

export const initProducts = () => dispatch =>{
  dispatch({type:FETCH_PRODUCTS_BEGIN})
  getProducts().then(res => {
    if(res.status === 400 || res.status === 403){
      dispatch({type:FETCH_PRODUCTS_FAILURE})
    }
    dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: res })
  })
  .catch(err => {
    dispatch({type:FETCH_PRODUCTS_FAILURE})
  })
}

export const initCategories = () => dispatch =>{
  dispatch({type:FETCH_CATEGORIES_BEGIN})
  getCategories().then(res => {
    if(res.status === 400 || res.status === 403){
      dispatch({type:FETCH_CATEGORIES_FAILURE})
    }
    dispatch({ type: FETCH_CATEGORIES_SUCCESS, payload: res});
  })
  .catch(err => {
    dispatch({type:FETCH_CATEGORIES_FAILURE})
  })
}


export const  addConfig = (configList) => ({
  type: ADD_CONFIG,
  payload: configList
});

export const removeConfig = (configList) => ({
  type: REMOVE_CONFIG,
  payload: configList
});

export const addExtra = (extraList) => ({
  type: ADD_EXTRA,
  payload: extraList
});

export const removeExtra = (extraList) => ({
  type: REMOVE_EXTRA,
  payload: extraList
});

export const clearConfig = (clearList) => ({
  type: CLEAR_CONFIG,
  payload: clearList
});
