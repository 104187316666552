import React, {Component} from 'react'
// import * as R from 'ramda'
import { Button, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux'


// import {Accordion, Card, Button} from 'react-bootstrap'
// import { IoIosSettings, IoIosAddCircleOutline  } from 'react-icons/io';
// import * as R from 'ramda'

import {
  addConfig,
  removeConfig
} from '../actions/productActions'

// pureComponent, only display data from props 
class ProductConfigable extends Component {
  constructor(props){
    super(props)
    this.state={
      ingredients: {},
      reload: this.props.reset
    }
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount(){
    this.setState({ingredients:this.props.configer})
  }

  UNSAFE_componentWillReceiveProps({reset}) {
    if(reset){
    this.setState({ingredients:this.props.configer})
    this.props.hadReload(false)
    }
  }
  
  onClick =(pid, ing)=> {
    if( this.state.ingredients[ing] ){
      this.props.addConfig({[pid]:ing})
      this.setState({ ingredients:{...this.state.ingredients, [ing]:false} })
      // this.props.configTracker(ing)
    }else{
      this.props.removeConfig({[pid]:ing})
      this.setState({ ingredients:{...this.state.ingredients, [ing]:true} })
      // this.props.configTracker(ing)
    }
  }


  render(){  
    // console.log('this.props', this.props)
  return (
    <>
    <ListGroup>
    {(
      Object.keys(this.state.ingredients).map((ingredient, index) => (
        <ListGroup.Item key={index}>
         <Button  size="sm" key={index} variant={( this.state.ingredients[ingredient]? "success" : "secondary") } className="" onClick={()=>this.onClick(this.props.pId,ingredient)}>{ingredient}</Button>
        </ListGroup.Item>
        )
      )
    )}
    </ListGroup>
    </>
  )

  }
}

// function mapStateToProps(state) {
//   // console.log('state', state)
//   return{
//     productCongfigs: fig =>
//   }
// }

function mapDispatchToProps(dispatch) {
  return {
    addConfig: config => dispatch(addConfig(config)),
    removeConfig: config => dispatch(removeConfig(config))
  }
}

export default connect(null, mapDispatchToProps)(ProductConfigable)

