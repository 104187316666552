import React, { Component } from 'react'
import { Button, Modal} from 'react-bootstrap'
import { reset } from 'redux-form'
import { connect } from 'react-redux'

import LoginForm from '../../interface/LoginForm'
import { login, setUserAuth } from '../../actions/authActions'
import { loginUserAccount } from '../../service/authService'
import { postUserAccount } from '../../service/authService'
import SignUp from '../../interface/SignupForm'

class SigninModal extends Component {
constructor(props){
  super(props) 
  this.state = {
    isSignUp : false
  } 

}
handelSignin = () => {
  this.setState({isSignUp:false})
}

handelSignup = () => {
  this.setState({isSignUp:true})
}

singin = async (values, dispatch) => {
    return loginUserAccount(values).then(
      res => {
        console.log(`res.string`, res.stringify())
        dispatch(reset('LoginForm'))
        this.props.setUserAuth({ userId: res.userId, userType: res.usertype, access_token: res.id })
        this.props.login()
        this.props.handleSinginClose()
        return res
      },
      reject => {
      }
    )
}

singup = async (values, dispatch) => {
  return postUserAccount(values).then(
    res => {
      dispatch(reset('SignUp'))
      this.props.handleClose()
      return res
    },
    reject => {
    }
  )
}

  render() {
    const { showModal, handleClose } = this.props
    return (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Signin & Signup</Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
          {
            this.state.isSignUp &&
            <SignUp onSubmit={(values, dispatch) => this.singup(values, dispatch)} />
          }
          {
            !this.state.isSignUp &&
            <LoginForm onSubmit={(values, dispatch) => this.singin(values, dispatch)} />
          }
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={ ()=>{this.handelSignin()} } >Signin</Button>
            <Button variant="primary" onClick={ ()=>{this.handelSignup()} } >SignUp</Button>
          </Modal.Footer>
        </Modal>
    )
  }
}

const mapDispatchToProps = {
  login,
  setUserAuth
};

const Signin = connect(null, mapDispatchToProps)(SigninModal);
export default Signin