import React, { Component } from 'react'
// import * as R from 'ramda'
import { Button, ListGroup } from 'react-bootstrap';
// import {Accordion, Card, Button} from 'react-bootstrap'
// import { IoIosSettings, IoIosAddCircleOutline  } from 'react-icons/io';
// import * as R from 'ramda'
import { connect } from 'react-redux'

import {
  addExtra,
  removeExtra
} from '../actions/productActions'

// pureComponent, only display data from props 
class ProductExtra extends Component {
  constructor(props) {
    super(props)

    this.state = {
      extras: [],
      selectedExtras: [],
      reload: this.props.reset
    }

    this.onClick = this.onClick.bind(this)
  }
  
  UNSAFE_componentWillReceiveProps({reset}) {
    if(reset){
      this.setState({ extras: this.props.extra })
      this.setState({ selectedExtras: [] })
      this.props.hadReload(false)
    }
  }

  componentDidMount() {
    this.setState({ extras: this.props.extra })
  }


  onClick = (pid, extraIngredient) => {
    // this.setState({ extras:{...this.state.extras, [extraIngredient]:true} })
    if (this.state.selectedExtras.includes(extraIngredient.name)) {
      // if the item been include then exclude and turn off remove it from selectedextra
      //FOR COLORING
      this.setState({
        selectedExtras: [
          ...this.state.selectedExtras.filter(extra => extra !== extraIngredient.name)
        ]
      })
      // remove it from store
      this.props.removeExtra({ [pid]: extraIngredient })
    } else {
      // if the item been not include then include and turn on, addto  selectedextra
      this.setState({ selectedExtras: [...this.state.selectedExtras, extraIngredient.name] })
      // add to store
      this.props.addExtra({ [pid]: extraIngredient })
    }
  }

  render() {
    const extraz = this.props.extra  // array
    
    return (
      <>
        <ListGroup>
          {(
            this.state.extras.map((extraIngredient, index) => (
              <ListGroup.Item key={index}>
                <Button
                  size="sm"
                  key={index}
                  variant={(this.state.selectedExtras.includes(extraIngredient.name) ? "success" : "secondary")}
                  className=""
                  onClick={() => this.onClick(this.props.pId, extraIngredient)}
                > {
                    extraIngredient.name + '   $'+ extraIngredient.price.regular/100 
                  }
                </Button>
              </ListGroup.Item>
            )
            )
          )}
        </ListGroup>
      </>
    )

  }
}



function mapDispatchToProps(dispatch) {
  return {
    addExtra: extra => dispatch(addExtra(extra)),
    removeExtra: extra => dispatch(removeExtra(extra))
  }
}

export default connect(null, mapDispatchToProps)(ProductExtra)
