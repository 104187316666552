import {
  ADD_TO_CART, 
  REMOVE_FROM_CART, 
  UPDATE_CART,
  UPLOAD_CART_SUCCESS, 
  UPLOAD_CART_FAILED
} from './actionTypes'


  export const addToCart = (myCart) => ({
    type: ADD_TO_CART,
    payload: myCart
  });

  export const removeFromCart = (myCart) => ({
    type: REMOVE_FROM_CART,
    payload: myCart
  });
  
  export const updateCart = (myCart) => ({
    type: UPDATE_CART,
    payload: myCart
  });

  export const uploadCartSuccess = (myCart) => ({
    type: UPLOAD_CART_SUCCESS,
    payload: myCart
  });

  export const uploadCartFailed = (myCart) => ({
    type: UPLOAD_CART_FAILED,
    payload: myCart
  });
