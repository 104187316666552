import * as types from '../actions/actionTypes'

const initialState = []

export default function categoryReducer (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CATEGORIES_SUCCESS:
      return action.payload
    default:
      return state
  }
}