import React, { Component } from "react";
import { connect } from "react-redux";
import "../index.css";
import { Link } from "react-router-dom";

import { Container, Row, Col, Modal } from "react-bootstrap";
import Orders from '../interface/Orders';

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  getProductsFromMenu = (product, products) => {
    let temp = [];
    product.add_product_menu.menu.forEach((menuName) => {
      temp = temp.concat(
        products.filter((prod) => prod.CategoryName === menuName)
      );
    });
    return temp;
  };

  // filterByCategory = (produt, cate, index, any_extras, including) => {
  //   if (produt.CategoryName === cate) {
  //     return (
  //       <ProductDetails key={index} product={produt} extras={any_extras} included={including}></ProductDetails>
  //     )
  //   }
  // }

  handelColse = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    // const handleClose = () => {
    //   this.setState({ showModal: !this.state.showModal });
    //   this.handleFlag()
    // };

    const flag_time = localStorage.getItem("modal");
    // console.log(`flag`, flag_time) //can be value old value

    const current = new Date();

    if (!flag_time) {
      localStorage.setItem("modal", current.getHours());
      this.setState({ showModal: !this.state.showModal });
    } else {
      let display_time = current.getHours();
      // console.log(`display_time`, display_time)
      if (parseInt(display_time) > parseInt(flag_time)) {
        localStorage.removeItem("modal");
      } else if (parseInt(display_time) < parseInt(flag_time)) {
        localStorage.removeItem("modal");
      }
    }

    const { categories } = this.props;
    let columns = [];
    // console.log(`categories`, this.props.categories)
    categories.map((category, index) => {
      columns.push(
        <Col md className="mt-5" key={index}>
          <a href={`/${category.name}`} className="team-card mb-5">
            <img className="img-fluid" src={category.media.hero} />
          </a>
          <Row className="bg-navbar-color">
            <Col className="d-flex align-items-center justify-content-center ">
              <h4>{category.name}</h4>
            </Col>
          </Row>
        </Col>
      );

      // force wrap to next row every 4 columns
      if ((index + 1) % 3 === 0) {
        columns.push(<div className="w-100" key={Math.random()}></div>);
      }
    });

    return (
      <>
        <Container>
          <Row className="mt-5">
            {columns}
            <Col md className="mt-5" />
            <Col md className="mt-5" />
          </Row>
        </Container>
        <Modal
          show={this.state.showModal}
          onHide={this.handelColse}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Order and Pick Up Only! </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ justifyContent: "center" }}>
              <h6 size="sm">Lunch : Wednesday to Saturday 11:30am-2:00pm </h6>
              <h6>Dinner : Monday to Sunday 4:00pm-9:00pm</h6>
              <h6 style={{color: '#FF101F', backgroundColor:'#ff9f0a'}}>Online Orders only open up to 8PM</h6>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    // products: state.products.sort( (a,b) => a.display_level.menu - b.display_level.menu ),
    categories: state.categories.filter(
      (category) => category.status === "active"
    ),
  };
}

export default connect(mapStateToProps)(CategoryList);
