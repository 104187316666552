
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART,
  UPLOAD_CART_SUCCESS,
  UPLOAD_CART_FAILED
} from '../actions/actionTypes';
import { PURGE, REHYDRATE } from 'redux-persist';

const initialState = { hasUpload: false, myCart: [], adding: false };

const cartReducer = (state = initialState, action) => {
  let count = 1
  switch (action.type) {

    case ADD_TO_CART:
      return {
        ...state,
        myCart: [ ...state.myCart, action.payload ],
        adding: true
      }

    case REMOVE_FROM_CART:
      return {
        ...state,
        myCart: [
          ...state.myCart.filter(
            (product) => !count || (count -= Object.keys(product)[0] === action.payload)
          ),
        ],
        adding: true,
      };
  
    case UPDATE_CART:
      return { ...state, myCart: action.payload, adding: true };
    case UPLOAD_CART_SUCCESS:
      return { ...state, myCart: [], adding: false, isSubmitted: true };
    case UPLOAD_CART_FAILED:
      return { ...state, myCart: action.payload, adding: false, isSubmitted: false };

    default:
      return state;
  }
}
export default cartReducer