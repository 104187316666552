import { combineReducers, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import {
  // persistCombineReducers,
  persistStore,
  persistReducer
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import { reducer as formReducer } from 'redux-form'

import productsReducer from './productReducer'
import categoryReducer from './categoryReducer'
import authReducer from './authReducer';
import productConfigReducer from './productConfigReducer'
import cartReducer from './cartReducer'


// config to not persist the *counterString* of the CounterReducer's slice of the global state.

const auth_config = {
  key: 'auth',
  storage: storage
};

const cart_config = {
  key: 'shorpping_cart',
  storage: storage
};




const rootReducer = combineReducers({
  AuthReducer:  persistReducer(auth_config, authReducer),
  products: productsReducer,
  categories:categoryReducer,
  productCongfigs: productConfigReducer,
  shoppingCart: persistReducer(cart_config, cartReducer),
  form: formReducer
})

export default rootReducer