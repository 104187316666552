import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  Accordion,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import { getOrders, updateOrderPaymentStatus } from "../service/orderService";
import { connect } from "react-redux";
import queryString from "query-string";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrders: [],
      showPaymentStatus: false,
      showModal: false,
      selectedOrder: null,
    };
    this.onShowPaymentStatusAlert = this.onShowPaymentStatusAlert.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
  }

  onShowPaymentStatusAlert = () => {
    this.setState({ showPaymentStatus: true }, () => {
      window.setTimeout(() => {
        this.setState({ showPaymentStatus: false });
      }, 1115000);
    });
  };

  onCloseAlert = () => {
    this.setState({ showPaymentStatus: false });
  };

  onOpenOrderModal = (selected) => {
    this.setState({ selectedOrder: selected });
    this.setState({ showModal: true });
  };

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);

    if (values.response) {
      this.onShowPaymentStatusAlert();
      let status = "";
      if (values.response === "Failure") {
        status = {
          payment: { poli: true },
          payment_status: "Failure",
          payment_recived: "false",
        };
      } else if (values.response === "Success") {
        status = {
          payment: { poli: true },
          payment_status: "Success",
          payment_recived: "true",
        };
      } else {
        status = {
          payment: { poli: true },
          payment_status: "Cancelled",
          payment_recived: "false",
        };
      }

      await updateOrderPaymentStatus(values.id, status).then(
        (res) => {
            setTimeout(function(){
              window.location.href ='/orders'
             },5000);
        },
        (rej) => {
          window.location.href ='/orders'
        }
      );
    }

    await getOrders(this.props.user.userId).then(
      (res) => {
        // console.log(`res.string`, res.stringify())
        this.setState({
          allOrders: res.sort(
            (a, b) => new Date(b.order_date) - new Date(a.order_date)
          ),
        });
      },
      (rej) => {}
    );
  }

  render() {
    let sum = 0;
    const qvalue = queryString.parse(this.props.location.search);
    return (
      <Container>
        {this.state.showPaymentStatus && (
          <>
            <Alert variant="danger" className="alert-top">
              <Alert.Heading>Your Payment {qvalue.response}</Alert.Heading>{" "}
              <p>Every order required minimum 15 to 20 minutes to cook. </p>{" "}
              <Button
                variant="outline-primary"
                onClick={() => this.onCloseAlert()}
                size="sm"
              >
                CLOSE
              </Button>{" "}
            </Alert>{" "}
          </>
        )}
        <h2>Your Recent Orders</h2>
        <br />
        <hr />
        <Row className="row-buttom">
          <Col xs={5} sm={4}  lg={3} style={{textAlign:"center"}}>
            <h6>Date:</h6>
          </Col>
          <Col xs={2} sm={2}  lg={2} style={{textAlign:"center"}}>
            <h6>ID:</h6>
          </Col>
          <Col xs={2} sm={2} lg={2} style={{textAlign:"center"}}>
            <h6>Cost:</h6>
          </Col>
          <Col xs={2} sm={4}  lg={3} className="d-none d-sm-block" style={{textAlign:"center"}}>
            <h6>Paymen Status:</h6>
          </Col>
        </Row>

        {this.state.allOrders.map((order, index) => {
          return (
            <Row
              className="row-buttom"
              onClick={() => this.onOpenOrderModal(order)}
              style={{
                color:
                  order.payment_status === "Cancelled"
                    ? "#FF5971"
                    : order.payment_status === "ready"
                    ? "#EA7317"
                    : order.payment_status === "Success"
                    ? "#4CB944"
                    : order.payment_status === "Failure"
                    ? "#FF101F"
                    : "#000",
              }}
            >
              <Col xs={5} sm={4} lg={3} style={{textAlign:"center"}} >
                <p>
                  {new Date(order.order_date).toLocaleString(
                    "en-NZ",
                    "New Zealand/Auckland"
                  )}
                </p>
              </Col>
              <Col xs={2} sm={2} lg={2} style={{textAlign:"center"}}>
                <p>{"#" + order.order_no}</p>
              </Col>
              <Col xs={3} sm={2} lg={2} style={{textAlign:"center"}}>
                <p> {"$" + order.total_charge.toFixed(2) / 100}</p>
              </Col>
              <Col xs={2} sm={4} lg={3} className="d-none d-sm-block" style={{textAlign:"center"}}>
                <p>{"Payment: " + order.payment_status}</p>
              </Col>
            </Row>
          );
        })}

        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.selectedOrder
                ? "Order ID #" + this.state.selectedOrder.order_no
                : ""}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <>
                {this.state.selectedOrder &&
                  this.state.selectedOrder.data.map((item, index) => {
                    sum += Object.values(item)[0].price / 100;
                    return (
                      <Row xs={12} sm={12} md={12} lg={12} key={index}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          {"["+Object.values(item)[0].category + "] "}
                          {Object.values(item)[0].name}
                          {"   $" + Object.values(item)[0].price / 100}

                          {Object.values(item)[0].configuer.length > 0 && (
                            <>
                              {Object.values(item)[0].configuer.map(
                                (ingredient, indx) => (
                                  <Row
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    key={indx}
                                  >
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {"- " + Object.values(ingredient)[0]}
                                  </Row>
                                )
                              )}
                            </>
                          )}

                          {Object.values(item)[0].extras.length > 0 && (
                            <>
                              {Object.values(item)[0].extras.map(
                                (ingredient, indx) => {
                                  sum += Object.values(ingredient)[0].price.regular / 100;
                                  return (
                                    <Row key={indx}>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      {"+ " +
                                        Object.values(ingredient)[0].name +
                                        "  $" +
                                        Object.values(ingredient)[0].price
                                          .regular /
                                          100}
                                    </Row>
                                  );
                                }
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
              </>
              <p style={{fontSize:14 ,color:"#aaa"}}>**[Payment: ready] Means not been process by user or payment session timeout.</p>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {this.state.selectedOrder && (
              <p>
                {new Date(this.state.selectedOrder.order_date).toLocaleString(
                  "en-NZ",
                  "New Zealand/Auckland"
                )}
              </p>
            )}

            {this.state.selectedOrder && (
              <p
                style={{
                  backgroundColor:
                    this.state.selectedOrder.payment_status === "Cancelled"
                      ? "#FF5971"
                      : this.state.selectedOrder.payment_status === "ready"
                      ? "#EA7317"
                      : this.state.selectedOrder.payment_status === "Success"
                      ? "#4CB944"
                      : this.state.selectedOrder.payment_status === "Failure"
                      ? "#FF101F"
                      : "#000",
                }}
              >
                {"Payment: " + this.state.selectedOrder.payment_status}
              </p>
            )}
            {this.state.selectedOrder && (
              <p>
                {"$" + this.state.selectedOrder.total_charge.toFixed(2) / 100}
              </p>
            )}
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.AuthReducer,
  };
}
export default connect(mapStateToProps)(Orders);
