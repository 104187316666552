import React, { Component } from 'react'
import { Accordion, Card, Button, Row, Col } from 'react-bootstrap'
import { FaPlus, FaChevronDown } from 'react-icons/fa';
import { connect } from 'react-redux'
import * as R from 'ramda'
import uuid from 'react-uuid'

import ProductConfigable from './productConfigable'
import ProductExtra from './productExtra'
import ProductInclude from './productInclude'
import { addToCart } from '../actions/cartActions'
import { clearConfig } from '../actions/productActions'
class ProductDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resetConfigs: false,
      resetExtras: false,
      isExtra: false,
      isIncluded: false
    }

    this.addtoCartOnClick = this.addtoCartOnClick.bind(this)
    this.didConfigReload = this.didConfigReload.bind(this)
    this.didExtraReload = this.didExtraReload.bind(this)
  }

  addtoCartOnClick = (pId) => {

    let configuer = this.props.productCongfigs.configs.filter(cofing => (Object.keys(cofing)[0] === pId.id))
    let extras = this.props.productCongfigs.extras.filter(extra => (Object.keys(extra)[0] === pId.id))

    this.props.addToCart({ [pId.id]: { "configuer": configuer, "extras": extras, "carttime": new Date().toLocaleString(), "price": pId.price.regular, "name": pId.name, "category": pId.CategoryName, "session": uuid() } })

    this.props.clearConfig(pId.id)
    //laste reload child state
    this.setState({ resetConfigs: !this.state.resetConfigs })
    this.setState({ resetExtras: !this.state.resetExtras })
  }

  didConfigReload = (reloaded) => {
    this.setState({ resetConfigs: reloaded })
  }

  didExtraReload = (reloaded) => {
    this.setState({ resetExtras: reloaded })
  }

  componentDidMount() {

    if (this.props.extras.length > 0) {
      this.setState({ isExtra: true })
    }

    if (this.props.included !== null || this.props.included !== "undefined" ) {
      this.setState({ isIncluded: true })
    }else {
      this.setState({ isIncluded: false })
    }

  }

  render() {
    const { product, extras, included } = this.props
    let isConfig = R.isNil(product.config)
    // console.log('included', included)
    return (
      <Accordion>
        <Card>
          <Card.Header>
            {this.props.product.name} {'$' + this.props.product.price.regular / 100}
            <Button variant="link" size="md" onClick={() => this.addtoCartOnClick(product)}>
              <FaPlus />
            </Button>
            {(!isConfig || this.state.isIncluded) &&
              <Accordion.Toggle as={Button} size="md" variant="link" eventKey="0">
                <FaChevronDown />
              </Accordion.Toggle>
            }
          </Card.Header>
         
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row>
                  <Col>
                  {!isConfig  &&
                    <ProductConfigable uuid={uuid()} configer={product.config} pId={product.id} reset={this.state.resetConfigs} hadReload={() => this.didConfigReload()} ></ProductConfigable>
                  }
                  {this.state.isIncluded &&
                    <ProductInclude uuid={uuid()} configer={included} pId={product.id} ></ProductInclude>
                  }

                  </Col>

                  <Col>
                    {this.state.isExtra &&
                      <ProductExtra extra={extras} pId={product.id} reset={this.state.resetExtras} hadReload={() => this.didExtraReload()} ></ProductExtra>
                    }
                  </Col>
                </Row>
                <Button variant="link" size="mds" onClick={() => this.addtoCartOnClick(product)}>
                  <FaPlus /> Add To Cart
                </Button>
              </Card.Body>
            </Accordion.Collapse>
          

        </Card>
      </Accordion>
    )
  }
}
// function mapStateToProps(state) {
function mapStateToProps(state) {
  return {
    products: state.products,
    categories: state.categories.slice(1),
    myCart: state.myCart,
    productCongfigs: state.productCongfigs
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearConfig: clear => dispatch(clearConfig(clear)),
    addToCart: product => dispatch(addToCart(product))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
