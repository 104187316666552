import React, { Component } from 'react'
import { Button, ListGroup } from 'react-bootstrap';

class ProductInclude extends Component {
  constructor(props) {
    super(props)
    this.state = {
      include_product: {},
      hasInclude:false
    }
  }

  componentDidMount() {
    if (this.props.configer && this.props.configer.length > 0) {
      this.setState({ include_product: this.props.configer, hasInclude:true })
    }else { this.setState({ include_product: {}, hasInclude:false }) }
  }

  render() {
// console.log('this.state.include_product', this.state.include_product)
    return (
      <ListGroup>
        {(
          this.state.hasInclude &&
          this.state.include_product.map((product, index) => (
            <ListGroup.Item key={index}>
              <Button size="sm" key={index} variant="info" className="" >{product}</Button>
            </ListGroup.Item>
          ))

        )}
      </ListGroup>
    )

  }
}

export default ProductInclude

