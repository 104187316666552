import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col } from 'react-bootstrap'
import ProductDetails from './productDetails'
class ProductList extends Component {

  getProductsFromMenu = (product, products) => {
    let temp = []
    product.add_product_menu.menu.forEach(menuName => {
      temp = temp.concat(products.filter(prod => prod.CategoryName === menuName))
    })
    return temp
  }

  filterByCategory = (produt, cate, index, any_extras, including) => {
    if (produt.CategoryName === cate) {
      return (
        <ProductDetails key={index} product={produt} extras={any_extras} included={including}></ProductDetails>
      )
    }
  }

  render() {
    const { categories, products } = this.props

    if (products && categories) {
      return categories.map((category, index) => (
        <Col sm={4} key={index}>
          <h2>{category.name}</h2>
          {
            products.map((product, indx) => {
              if (product.add_product_menu && product.add_product_menu.hasOwnProperty('menu')) {
                //has both
                if (product.include_products && product.include_products.hasOwnProperty('menu')) {
                  return this.filterByCategory(product, category.name, indx, this.getProductsFromMenu(product, products), product.include_products.menu)
                } else return this.filterByCategory(product, category.name, indx, this.getProductsFromMenu(product, products), null)

              } else {
                if (product.include_products && product.include_products.hasOwnProperty('menu')) {
                  return this.filterByCategory(product, category.name, indx, [], product.include_products.menu)
                } else return this.filterByCategory(product, category.name, indx, [], null)

              }
            })
          }
        </Col>
      ))
    }
  }
}

function mapStateToProps(state) {
  return {
    products: state.products.sort( (a,b) => a.display_level.menu - b.display_level.menu ),
    categories: state.categories.filter(category => category.status === "active")
  }
}

export default connect(mapStateToProps)(ProductList)