import axios from 'axios';

axios.defaults.baseURL = 'https://efun.co.nz/flying/api';


export const postCategory = ( postCat ) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: '/Categories',
      headers: {
        'Content-Type': 'application/json'
      },
      data: postCat
    })
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err.response);
    });
  });
}

export const getCategories = ( ) =>{
    return new Promise((resolve, reject) => {
  
        axios({
          method: 'get',
          url: '/Categories',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          if(res.status === 400 || res.status === 403){
            reject(res);
          }
          resolve(res.data);
  
        })
        .catch(err => {
          reject(err.response);
        });
      });
  }