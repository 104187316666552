import React, { Component } from "react";
import {
  Accordion,
  Card,
  Button,
  Row,
  Col,
  Container,
  Modal,
} from "react-bootstrap";
import { FaPlus, FaMinus, FaChevronDown } from "react-icons/fa";
import { connect } from "react-redux";
import * as R from "ramda";
import uuid from "react-uuid";

import ProductConfigable from "./productConfigable";
import ProductExtra from "./productExtra";
import ProductInclude from "./productInclude";
import { addToCart, removeFromCart } from "../actions/cartActions";
import { clearConfig } from "../actions/productActions";

class ProductBoxDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetConfigs: false,
      resetExtras: false,
      isExtra: false,
      isIncluded: false,
      showModal: false,
      totalItemCartCount:0
    };

    this.addtoCartOnClick = this.addtoCartOnClick.bind(this);
    this.didConfigReload = this.didConfigReload.bind(this);
    this.didExtraReload = this.didExtraReload.bind(this);
  }

  addtoCartOnClick = (pId) => {
    let configuer = this.props.productCongfigs.configs.filter(
      (cofing) => Object.keys(cofing)[0] === pId.id
    );
    let extras = this.props.productCongfigs.extras.filter(
      (extra) => Object.keys(extra)[0] === pId.id
    );

    this.props.addToCart({
      [pId.id]: {
        configuer: configuer,
        extras: extras,
        carttime: new Date().toLocaleString(),
        price: pId.price.regular,
        name: pId.name,
        category: pId.CategoryName,
        session: uuid(),
      },
    });

    this.props.clearConfig(pId.id);
    //laste reload child state
    this.setState({ resetConfigs: !this.state.resetConfigs });
    this.setState({ resetExtras: !this.state.resetExtras });

  };

  removeItemFromCart = (productId) =>{
    this.props.removeFromCart(productId)
  }

  didConfigReload = (reloaded) => {
    this.setState({ resetConfigs: reloaded });
  };

  didExtraReload = (reloaded) => {
    this.setState({ resetExtras: reloaded });
  };

  componentDidMount() {

    if (this.props.extras.length > 0) {
      this.setState({ isExtra: true });
    }

    if (this.props.included !== null || this.props.included !== "undefined") {
      this.setState({ isIncluded: true });
    } else {
      this.setState({ isIncluded: false });
    }
  }

  render() {
    // ONE PRODUCT
    //incoming props
    const { index, product, extras, included, shoppingCart } = this.props;

    const hasItem = shoppingCart.myCart.filter((x) =>Object.keys(x)[0] === product.id ).length
    // console.log(`hasItem`, hasItem)
    // if(hasItem > 0){
    //   this.setState({totalItemCartCount :hasItem })
    // }

    let isConfig = R.isNil(product.config);

    const handleClose = () => {
      this.setState({ showModal: false });
    };

    return (
      <>
        <Col md className="mt-5 " key={index}>
          {/* <img
            className="img-fluid"
            src={product.images ? product.images.hero : "/static/hero.jpg"}
            onClick={() => {
              this.setState({ showModal: true });
            }}
          /> */}
          {/* <Row className=" bg-navbar-color" >
            <Col xs={8}>
              <Card.Title className="mt-2">
                {product.name} {"$" + product.price.regular / 100}
              </Card.Title>
            </Col>
            <Col xs={4}>
              <Button
                variant="link"
                size="md"
                onClick={() => this.addtoCartOnClick(product)}
              >
                <FaPlus />
              </Button>
            </Col>
          </Row> */}

          <Row className=" menus align-items-start">
            <Col xs={8} className="price-col">
              <a
                href="#"
                className="nav-link menu-a"
                onClick={() => {
                  this.setState({ showModal: true });
                }}
              >
                {product.name}
              </a>
            </Col>
            <Col xs={4} className="justify-content-center menu-price price-col">
              <Row className="justify-content-center" >
                <h5 className="text-muted menu-price ">
                  {"$" + product.price.regular / 100}
                </h5>
              </Row>
              <Row >
                <Col  className="justify-content-center align-items-center">
                  <Button
                   className="buttoning"
                   variant="light"
                    size="sm"
                   disabled={hasItem > 0 ? false : true}
                    onClick={() => this.removeItemFromCart(product.id)}
                  >
                    <FaMinus />
                  </Button>
                </Col>
                <Col className=" d-flex justify-content-center align-items-center">
                  <Button
                  className="value-button"
                    variant="secondary"
                    size="sm"
                    disabled
                  >
                    {hasItem > 0 ? hasItem : 0}
                     {/* {this.state.totalItemCartCount === 0 ? 0 : this.state.totalItemCartCount} */}
                  </Button>
                </Col>
                <Col className=" justify-content-center align-items-center">
                  <Button
                   className="buttoning"
                   variant="light"
                    size="sm"
                    onClick={() => this.addtoCartOnClick(product)}
                  >
                    <FaPlus />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Modal
          show={this.state.showModal}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{product.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ justifyContent: "center" }}>
              {product.images && (
                <>
                  <img
                    className="img-fluid"
                    src={
                      product.images ? product.images.hero : "/static/hero.jpg"
                    }
                  />
                  <p size="sm">
                    ALL PICTURES SHOWN ARE FOR ILLUSTRATION PURPOSES ONLY.
                  </p>
                </>
              )}
            </Row>
            {(!isConfig || this.state.isIncluded) && (
              <Container>
                <Row>
                  <Col>
                    Come-with
                    {!isConfig && (
                      <ProductConfigable
                        uuid={uuid()}
                        configer={product.config}
                        pId={product.id}
                        reset={this.state.resetConfigs}
                        hadReload={() => this.didConfigReload()}
                      ></ProductConfigable>
                    )}
                    {this.state.isIncluded && (
                      <ProductInclude
                        uuid={uuid()}
                        configer={included}
                        pId={product.id}
                      ></ProductInclude>
                    )}
                  </Col>

                  <Col>
                    Extra
                    {this.state.isExtra && (
                      <ProductExtra
                        extra={extras}
                        pId={product.id}
                        reset={this.state.resetExtras}
                        hadReload={() => this.didExtraReload()}
                      ></ProductExtra>
                    )}
                  </Col>
                </Row>
              </Container>
            )}
          </Modal.Body>
          <Modal.Footer>
            GREEN color is selected
            <Button
              variant="link"
              size="mds"
              onClick={() => {
                this.addtoCartOnClick(product);
                handleClose();
              }}
            >
              <FaPlus /> Add To Cart
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
// function mapStateToProps(state) {
function mapStateToProps(state) {
  return {
    categories: state.categories.slice(1),
    shoppingCart: state.shoppingCart,
    productCongfigs: state.productCongfigs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearConfig: (clear) => dispatch(clearConfig(clear)),
    addToCart: (product) => dispatch(addToCart(product)),
    removeFromCart:(productId)=>dispatch(removeFromCart(productId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBoxDetails);
