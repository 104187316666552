import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Spinner } from 'react-bootstrap'
import renderTextBox from '../components/inputs/renderTextBox'

class LoginForm extends Component {
  render() {
    const { error, handleSubmit, submitting } = this.props
    return (
      <Form onSubmit={handleSubmit}>
        <h3>Login</h3>
        <br/>
        <Field
          name="username"
          type="text"
          component={renderTextBox}
          label="Email"
        />
        <Field
          name="password"
          type="password"
          component={renderTextBox}
          label="Password"
        />
        {error && <strong>{error}</strong>}
        <br/>
        <Button type="submit"
          disabled={submitting}>
          {submitting ? <Spinner animation="border" variant="danger" /> : "Confirm"}
        </Button>
      </Form>
    )
  }
}
export default reduxForm({
  form: 'LoginForm' // a unique identifier for this form
})(LoginForm)
