import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import renderTextBox from './renderTextBox'
import { Form, Button, Spinner } from 'react-bootstrap'

class CreateProduct extends Component {
    render() {
        const { error, handleSubmit, submitting, categoies } = this.props
        return (
            <Form onSubmit={handleSubmit}>
                <br />
                <label>Parent Category</label>
                <br />
                <Field name="CategoryName" component="select">
                    <option></option>
                    {categoies && categoies.map((cat, idx) => (
                        <option key={idx} value={cat.name}>{cat.name}</option>
                    ))}
                </Field>
                <Field
                    name="name"
                    type="text"
                    component={renderTextBox}
                    label="Name"
                />
                <Field
                    name="title"
                    type="text"
                    component={renderTextBox}
                    label="Title"
                />
                <Field
                    name="content"
                    type="text"
                    component={renderTextBox}
                    label="Content"
                />

                <Field
                    name="config"
                    type="text"
                    component={renderTextBox}
                    label='Config{"salt":true}'
                />

                <Field
                    name="slug"
                    type="text"
                    component={renderTextBox}
                    label="Slug"
                />

                <Field
                    name="description"
                    type="text"
                    component={renderTextBox}
                    label="Description"
                />

                <Field
                    name="price"
                    type="string"
                    component={renderTextBox}
                    label='Price 550=5.5 {"regular":550}'
                />

                <Field
                    name="discount_rate"
                    type="number"
                    component={renderTextBox}
                    label="Discount Rate%off"
                />
                <Field
                    name="status"
                    type="text"
                    component={renderTextBox}
                    label='Status{"active":true}'
                />
                <Field
                    name="size"
                    type="text"
                    component={renderTextBox}
                    label="Size"
                />
                <Field
                    name="include_products"
                    type="text"
                    component={renderTextBox}
                    label="Include Product{-}"
                />
                <Field
                    name="weight"
                    type="number"
                    component={renderTextBox}
                    label="Weight/kg"
                />
                <Field
                    name="display_level"
                    type="text"
                    component={renderTextBox}
                    label='Display Level{"menu":1}'
                />
                <Field
                    name="add_product_menu"
                    type="text"
                    component={renderTextBox}
                    label='Add Product menu{"menu":["food meun", "oother food menu"]}'
                />
                <Field
                    name="data"
                    type="text"
                    component={renderTextBox}
                    label='Data{"productMessage":"whatever"}'
                />
                <br />

                {error && <strong>{error}</strong>}
                <Button type="submit" disabled={submitting}
                    style={{ marginTop: 10 }}
                >
                    {submitting ? <Spinner animation="border" variant="danger" /> : "Save"}
                </Button>
            </Form>
        )
    }
}
export default reduxForm({
    form: 'CreateProduct' // a unique identifier for this form
})(CreateProduct)
