import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Spinner } from 'react-bootstrap'
import renderTextBox from '../components/inputs/renderTextBox'

class SignupForm extends Component {
    render() {
        const { error, handleSubmit, submitting } = this.props
        return (
            <Form onSubmit={handleSubmit}>
                <h3>Signup</h3>
                <br />
                <Field
                    name="email"
                    type="text"
                    component={renderTextBox}
                    label="Email/User Name"
                />
                <Field
                    name="password"
                    type="password"
                    component={renderTextBox}
                    label="Password"
                />
                <Field
                    name="first_name"
                    type="text"
                    component={renderTextBox}
                    label="Full Name"
                />

                <Field
                    name="phone"
                    type="number"
                    component={renderTextBox}
                    label="Phone No."
                />

                <br />
                {error && <strong>{error}</strong>}
                <Button type="submit"
                    disabled={submitting}>
                    {submitting ? <Spinner animation="border" variant="danger" /> : "Confirm"}
                </Button>
            </Form>
        )
    }
}
export default reduxForm({
    form: 'SignupForm' // a unique identifier for this form
})(SignupForm)
