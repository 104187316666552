import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import renderTextBox from './renderTextBox'
import { Form, Button, Spinner } from 'react-bootstrap'



class CreateCategory extends Component {

  render() {
    const { error, handleSubmit, submitting, categoies } = this.props
    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          component={renderTextBox}
          label="Name"
        />
        <Field
          name="description"
          type="text"
          component={renderTextBox}
          label="description"
        />
        <Field
          name="list_order"
          type="number"
          component={renderTextBox}
          label="List Order"
        />
        <br/>
        <label>Parent Category</label>
        <br/>
        <Field name="parent_id" component="select">
        <option></option>
        {categoies && categoies.map((cat, idx) => (
          <option key={idx} value={cat.id}>{cat.name}</option>
        ) )}
       </Field>

        <br />

        {error && <strong>{error}</strong>}
        <Button type="submit" disabled={submitting}
          style={{ marginTop: 10 }}>
          {submitting ? <Spinner animation="border" variant="danger" /> : "Save"}
        </Button>
      </Form>
    )
  }
}
export default reduxForm({
  form: 'CreateCategory' // a unique identifier for this form
})(CreateCategory)
