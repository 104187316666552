// return a list of ptoducts within category
import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Col, Container } from "react-bootstrap";
import ProductDetails from "../components/productDetails";

import ProductBoxDetailes from "../components/ProductBoxDetailes";

import ProductListDetailes from "../components/ProductListDetailes";

class Products extends Component {
  getProductsFromMenu = (product, products) => {
    let temp = [];
    product.add_product_menu.menu.forEach((menuName) => {
      temp = temp.concat(
        products.filter((prod) => prod.CategoryName === menuName)
      );
    });
    return temp;
  };

  makeSingleProductBox = (produt, index, any_extras, including) => {
    let columns = [];
    columns.push(
      <ProductListDetailes
        key={index}
        product={produt}
        extras={any_extras}
        included={including}
      ></ProductListDetailes>
    );
    let reminder = (index + 1) % 3
    if ( reminder === 0) {
      columns.push(<div className="w-100" key={Math.random()}></div>);
    }
    return columns;
  };

  containsObject(categoryName, allCategoris) {
    var i;
    for (i = 0; i < allCategoris.length; i++) {
      if (allCategoris[i] === categoryName) {
        return true;
      }
    }
    return false;
  }

  filterProductsByCategory(all_products, category_name) {
    return all_products.filter(
      (product) => product.CategoryName === category_name
    );
  }

  render() {
    const {
      match: { params },
      products,
      all_categories,
    } = this.props;

    const category_name = params.name;

    let category_exists = all_categories.filter(
      (cat) => cat.name === category_name
    );

    if (products && category_exists.length) {
      let productByCategory = this.filterProductsByCategory(
        products,
        category_name
      );

      return (
        <Container className="mb-5">
          <h1 className="mt-5" style={{textAlign:"center"}} >{category_name}</h1>
          <Row className="mb-5">
            {productByCategory.map((product, indx) => {

              if (
                product.add_product_menu &&
                product.add_product_menu.hasOwnProperty("menu")
              ) {
                //has both
                if (
                  product.include_products &&
                  product.include_products.hasOwnProperty("menu")
                ) {
                  return this.makeSingleProductBox(
                    product,
                    indx,
                    this.getProductsFromMenu(product, products),
                    product.include_products.menu
                  );
                } else
                  return this.makeSingleProductBox(
                    product,
                    indx,
                    this.getProductsFromMenu(product, products),
                    null
                  );
              } else {
                if (
                  product.include_products &&
                  product.include_products.hasOwnProperty("menu")
                ) {
                  return this.makeSingleProductBox(
                    product,
                    indx,
                    [],
                    product.include_products.menu
                  );
                } else
                  return this.makeSingleProductBox(product, indx, [], null);
              }
            })}

            {  (productByCategory.length + 1) % 3 === 2 ? <> <Col md className="mt-5" /> <Col md className="mt-5" /></>: <></> }
            {  (productByCategory.length + 1) % 3 === 1 ? <Col md className="mt-5" />: <></>}
          </Row>
        </Container>
      );
    } else {
      return <h1 style={{textAlign:"center"}} >No product for this category.</h1>;
    }
  }
}

function mapStateToProps(state) {
  return {
    products: state.products.sort(
      (a, b) => a.display_level.menu - b.display_level.menu
    ),
    all_categories: state.categories.filter(
      (category) => category.status === "active"
    ),
  };
}

export default connect(mapStateToProps)(Products);
