import * as types from '../actions/actionTypes'

const initialState = {
  configs: [],
  extras: []
}

export default function productConfigReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_CONFIG:
      return {
        ...state,
        configs: [...state.configs, action.payload]
      }
    case types.REMOVE_CONFIG:
      return {
        ...state,
        configs: [
          ...state.configs.filter(cofing => (JSON.stringify(cofing) !== JSON.stringify(action.payload)))
        ]
      }

    case types.ADD_EXTRA:
      return {
        ...state,
        extras: [...state.extras, action.payload]
      }

    case types.REMOVE_EXTRA:
      return {
        ...state,
        extras: [...state.extras.filter(extra => (JSON.stringify(extra) !== JSON.stringify(action.payload)))]
      }
// remove all objects with this product key
    case types.CLEAR_CONFIG:
      return {
        ...state,
        configs: [...state.configs.filter(config => Object.keys(config)[0] !== action.payload)],
        extras: [...state.extras.filter(extra => Object.keys(extra)[0] !== action.payload)]
      }

    default:
      return state
  }
}